<template>
  <div>
    <AuthorizationListContainerV2/>
  </div>
</template>

<script>
import AuthorizationListContainerV2 from '@/components/v2/authorization/AuthorizationListContainerV2'
export default {
    name: 'HomeV2',
    components: {
        AuthorizationListContainerV2
    }
}
</script>
