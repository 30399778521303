<template>
  <v-alert
    v-model="activator"
    dismissible
    dense
    border="left"
    elevation="2"
    colored-border
    :type="alertType"
  >
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  name: 'Alert',

  data() {
    return {
      activator: false,
      message: '',
      alertType: 'error'
    }
  },

  methods: {
    open({ message, type }) {
      this.activator = true
      this.message = message
      this.alertType = type
    },
    close() {
      this.activator = false
    }
  }
}
</script>
