<template>
  <v-container fluid class="px-0 mx-0">
    <v-row class="grey lighten-5 px-6">
      <v-col cols="12">
        <v-row>
          <v-col>
            <Alert ref="alert" />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6" md="auto">
            <span class="secondary--text text-h4 text-sm-h3">
              {{ $t('authorizationList.title') }}
            </span>
          </v-col>

          <v-col cols="6" md="auto">
            <v-btn
              block
              depressed
              color="primary"
              class="ml-4 rounded"
              :small="$vuetify.breakpoint.xsOnly"
              @click="onNewAuthorization"
            >
              <v-icon small>mdi-plus</v-icon>
              <span>Nueva</span>
            </v-btn>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col cols="12" md="4">
            <v-text-field
              hide-details
              outlined
              dense
              class="rounded"
              append-icon="mdi-magnify"
              :placeholder="$t('authorizationList.filters.inputSearchPlaceholder')"
              @input="searchFunction"
            >
            </v-text-field>
          </v-col>

          <v-col v-if="$vuetify.breakpoint.smAndDown">
            <FiltersAppliedMobile
              :coverageFilters="coverageTypes"
              :orderByItems="orderByItems"
              :statusFilters="states"
              @applyFilters="applyFiltersMobile"
              ref="filtersAppliedMobile"
            />
          </v-col>

          <v-col v-if="$vuetify.breakpoint.mdAndUp">
            <v-select
              v-model="coverageTypeModel"
              multiple
              outlined
              hide-details
              return-object
              class="py-0 rounded"
              :items="coverageTypes"
              :menu-props="{ maxHeight: '400' }"
              :dense="!$vuetify.breakpoint.smAndDown"
              :label="$t('authorizationList.header.coverage')"
              @change="onSelectChange($event, 'coverageType')"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">
                  <span>{{ $t(item.text) }}</span>
                </span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption ml-1"
                >
                  (+{{ coverageTypeModel.length - 1 }})
                </span>
              </template>
            </v-select>
          </v-col>

          <v-col v-if="$vuetify.breakpoint.mdAndUp">
            <v-select
              v-model="statusModel"
              dense
              multiple
              outlined
              hide-details
              return-object
              class="py-0 rounded"
              :items="states"
              :menu-props="{ maxHeight: '400' }"
              :label="$t('authorizationList.filters.stateFilterLabel')"
              @change="onSelectChange($event, 'status')"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">
                  <span>{{ $t(item.text) }}</span>
                </span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption ml-1"
                >
                  (+{{ statusModel.length - 1 }})
                </span>
              </template>
            </v-select>
          </v-col>

          <v-col v-if="$vuetify.breakpoint.mdAndUp">
            <v-menu
              offset-y
              min-width="290px"
              ref="menu"
              v-model="dateRangeMenu"
              transition="scale-transition"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  readonly
                  outlined
                  clearable
                  hide-details
                  class="py-0 rounded"
                  v-model="dateRangeText"
                  v-on="on"
                  v-bind="attrs"
                  :label="$t('authorizationList.dateFilter')"
                  @click:clear="clearDates"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                range
                no-title
                color="primary"
                @change="selectedDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row align="center" class="osg-gray-4 px-6">
      <v-col cols="auto">
        <span class="secondary--text text-h7">
          {{totalResultText}}
        </span>
      </v-col>

      <v-col class="mb-3" v-if="$vuetify.breakpoint.mdAndUp">
        <FiltersApplied
          :coverageFilters="coverageTypeModel"
          :statusFilters="statusModel"
          :selectedDates="dateRangeText"
          @cleanAll="cleanAll"
          @removeFilter="removeFilter"
        />
      </v-col>

      <v-col cols="auto" md="auto" v-if="$vuetify.breakpoint.mdAndUp">
        <span class="text-subtitle-2 osg-black--text">
          {{ $t('authorizationList.orderBy') }}
        </span>
      </v-col>

      <v-col cols="2" v-if="$vuetify.breakpoint.mdAndUp">
        <v-select
          v-model="orderByModel"
          dense
          outlined
          hide-details
          class="py-0 rounded"
          background-color="white"
          :items="orderByItems"
          :menu-props="{ maxHeight: '400' }"
          @change="onOrderSelected"
        >
        </v-select>
      </v-col>
    </v-row>

    <v-row class="px-6">
      <v-col cols="12">
        <AuthorizationList
          :authorizations="authorizations"
          :loading="authorizationLoading"
          :pagination="filters.page"
          @onChangePage="(page) => filters.page = page"
          @onChangeItemsPerPage="(size) => filters.size = size"
          @cleanAll="cleanAll"
        />
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import moment from 'moment'
import { createNamespacedHelpers } from 'vuex'
import { types, orderBy, statusFiltersHospitalization } from '@/config/v2/authorization-v2'

import Alert from './list-components/Alert'
import FiltersAppliedMobile from '@/components/v2/filters/FiltersAppliedMobile'
import FiltersApplied from '@/components/v2/filters/FiltersApplied'
import AuthorizationList from '@/components/v2/authorization/AuthorizationList'

const authorizationModule = createNamespacedHelpers('authorizationV2');

export default {
  name: 'AuthorizationListContainerV2',

  components: {
    Alert,
    FiltersAppliedMobile,
    FiltersApplied,
    AuthorizationList
  },

  mounted() {
    this.resetSearch();
    this.getAuthorizationsForm();
    this.showAlerts();
  },

  data() {
    return {
      coverageTypeModel: [],
      statusModel: [],
      orderByModel: 'createdAt,desc',
      dateRangeMenu: false,
      dateRangeText: '',
      dates: [],
      filters: {
        codeSearchValue: null,
        coverageType: null,
        status: null,
        dateFrom: null,
        dateTo: null,
        sort: 'createdAt,desc',
        page: 1,
        size: 12
      }
    }
  },

  methods: {
    ...authorizationModule.mapActions([
      "getAuthorizationsWithoutClaims",
      "getAuthorizationsForm",
      "setReimbursementStatus"
    ]),

    showAlerts() {
      if(this.reimbursementStatus === 'ERROR'){
        this.openAlert('error', this.$i18n.t('addAuthorization.errorLabel'))
        this.setReimbursementStatus(null)
      }

      if(this.reimbursementStatus === 'SUCCESS'){
        this.openAlert('success', this.$i18n.t('addAuthorization.successLabel'))
        this.setReimbursementStatus(null)
      }

      setTimeout(() => {
        this.$refs.alert?.close()
      }, 5000)
    },

    openAlert(type, message){
      this.$refs.alert.open({ type, message})
    },

    resetSearch() {
      this.filters.page = 1;
      this.loadAuthorizations();
    },

    loadAuthorizations() {
      this.getAuthorizationsWithoutClaims({...this.filters, page: this.filters.page - 1})
    },

    onNewAuthorization() {
      this.$router.push({
        name: 'AddAuthorizationV2'
      });
    },

    searchFunction($event){
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => {
        this.filters.codeSearchValue = $event;
      }, 900);
    },

    onSelectChange($event, key){
      this.filters[key] = $event.map(item => item.value).join(',');
    },

    selectedDate(date) {
      if(date[0] > date[1]){
        let aux = date[0];
        date[0] = date[1];
        date[1] = aux;
      }

      this.filters.dateFrom = moment(date[0]).toISOString();
      this.filters.dateTo = moment(date[1]).endOf('day').toISOString();
      this.dateRangeText = date[0]  + " ~ " + date[1];
    },

    removeFilter($event){
      const { type, value } = $event;

      switch(type){
        case 'coverage':
          this.coverageTypeModel = this.coverageTypeModel.filter(item => item.value !== value.slug)
          this.filters.coverageType = this.coverageTypeModel.map(item => item.value).join(',');
          break;

        case 'status':
          this.statusModel = this.statusModel.filter(item => item.value !== value.value)
          this.filters.status = this.statusModel.map(item => item.value).join(',');
          break;

        case 'dates':
          this.clearDates();
          break;
      }
    },

    cleanAll(){
      this.clearCoverage();
      this.clearStatus();
      this.clearDates();

      this.$refs.filtersAppliedMobile?.clearAll();
      this.loadAuthorizations();
    },

    onOrderSelected($event){
      this.filters.sort = $event;
    },

    clearCoverage(){
      this.coverageTypeModel = [];
      this.filters.coverageType = null;
    },

    clearStatus(){
      this.statusModel = [];
      this.filters.status = null;
    },

    clearDates(){
      this.dateRangeText = '';
      this.filters.dateFrom = null;
      this.filters.dateTo = null;
      this.dates = [];
    },

    applyFiltersMobile(filtersMobile){
      if(filtersMobile.clearAll){
        this.cleanAll();
      }else{
        this.filters.coverageType = filtersMobile.coverageTypes.map(types => types.value).join(',');
        this.filters.status = filtersMobile.statuses.map(status => status.value).join(',');
        this.filters.orderBy = filtersMobile.orderBy;
        this.filters.sort = filtersMobile.sort;
        this.filters.fromDate = filtersMobile.fromDate;
        this.filters.toDate = filtersMobile.toDate;
      }
    },
  },

  computed: {
    ...authorizationModule.mapGetters([
      "page",
      "authorizationForms",
      "reimbursementStatus",
      "authorizations",
      "authorizationLoading"
    ]),

    totalResultText() {
      const result = this.page?.totalElements ?? 0;
      return this.$tc("authorizationList.resultsTitle", result, {result});
    },

    coverageTypes() {
      const coveragesTypes = [];

      this.authorizationForms.forEach(form => {
        const coverageType = types.find(type => form.authorizationCoverage === type.slug)
        if (coverageType) {
          coveragesTypes.push(coverageType);
        }
      })

      return coveragesTypes.map(type => ({...type, text: this.$t(type.text)}));
    },

    orderByItems() {
      return orderBy.map(order => ({...order, text: this.$t(order.text)}));
    },

    states() {
      return statusFiltersHospitalization.map(status => ({
        ...status,
        text: this.$t(status.text)
      }))
    }
  },

  watch: {
    filters: {
      deep: true,
      immediate: true,
      handler() {
        this.loadAuthorizations();
      }
    }
  }
}
</script>
