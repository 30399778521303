<template>
    <div>
        <v-btn
            text
            depressed color="primary"
            v-if="$vuetify.breakpoint.smAndDown"
            @click.stop="filtersMobile = !filtersMobile"
            class="rounded"
            block
            style="background-color: #F1F1F1;"
        >
            <v-icon small>mdi-filter-variant</v-icon>
            <span class="pl-1">{{$t('authorizationList.filtersTitle')}}</span>
            <v-icon x-small class="pb-2" v-if="filtersSelected">fiber_manual_record</v-icon>
        </v-btn>

        <v-navigation-drawer
            v-model="filtersMobile"
            temporary
            right
            fixed
            width="75%"
        >
            <v-container px-8>
                <v-row>
                    <v-col cols="12">
                        <div class="d-flex justify-space-between">
                            <span class="secondary--text text-h4">{{$t('authorizationList.filtersTitle')}}</span>
                            <v-icon @click="closeFilters" size="25" color="secondary" class="mb-4">close</v-icon>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <FiltersApplied
                            :coverageFilters="selectedTypes"
                            :statusFilters="selectedStatus"
                            :selectedDates="selectedDateChip"
                            @removeCoverageFilter="removeCoverageFilter"
                            @removeStatusFilter="removeStatusFilter"
                            @removeSelectedDates="removeSelectedDates"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-list dense>
                            <v-list-group v-model="colapse.coverages">
                                <template v-slot:activator>
                                    <v-list-item-title>
                                        <span class="secondary--text text-h8">
                                            {{$t('authorizationList.header.coverage')}}
                                        </span>
                                    </v-list-item-title>
                                </template>

                                    <v-list-item-group
                                        v-model="selectedTypes"
                                        @change="onSelectedTypes"
                                        multiple
                                    >
                                        <template v-for="(coverage) in coverageFilters">
                                            <v-list-item
                                                :key="coverage.slug"
                                                :value="coverage"
                                                active-class="white"
                                            >
                                                <template v-slot:default="{ active }">
                                                    <v-list-item-action>
                                                        <v-checkbox
                                                        :input-value="active"
                                                        color="primary"
                                                        ></v-checkbox>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-text="coverage.text"></v-list-item-title>
                                                    </v-list-item-content>
                                                </template>
                                            </v-list-item>
                                        </template>
                                    </v-list-item-group>
                            </v-list-group>
                        </v-list>
                    </v-col>
                </v-row>


                <v-row>
                    <v-col cols="12">
                        <v-list dense>
                            <v-list-group v-model="colapse.states">
                                <template v-slot:activator>
                                    <v-list-item-title>
                                        <span class="secondary--text text-h8">
                                            {{$t('authorizationList.filters.stateFilterLabel')}}
                                        </span>
                                    </v-list-item-title>
                                </template>

                                <v-list-item-group
                                    v-model="selectedStatus"
                                    multiple
                                >
                                    <template v-for="(status) in stateList.length > 0 ? stateList : statusFilters">
                                        <v-list-item
                                            :key="status.slug"
                                            :value="status"
                                            active-class="white"
                                        >
                                            <template v-slot:default="{ active }">
                                                <v-list-item-action>
                                                    <v-checkbox
                                                    :input-value="active"
                                                    color="primary"
                                                    ></v-checkbox>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="status.text"></v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </v-list-item>
                                    </template>
                                </v-list-item-group>
                            </v-list-group>
                        </v-list>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-list dense>
                            <v-list-group v-model="colapse.dates">
                                <template v-slot:activator>
                                    <v-list-item-title>
                                        <span class="secondary--text text-h8">
                                            {{$t('authorizationList.dateFilter')}}
                                        </span>
                                    </v-list-item-title>
                                </template>
                                <v-row>
                                    <v-col>
                                        <v-menu
                                            ref="menu"
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                dense
                                                outlined
                                                hide-details
                                                readonly
                                                clearable
                                                :label="$t('authorizationList.dateFilter')"
                                                v-model="dateRangeText"
                                                background-color="white"
                                                class="py-0 rounded"
                                                v-bind="attrs"
                                                v-on="on"
                                                @click:clear="clearDates"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="dates"
                                                range
                                                @change="selectedDate"
                                                color="primary"
                                                no-title
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-list-group>
                        </v-list>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-list dense>
                            <v-list-group v-model="colapse.orderBy">
                                <template v-slot:activator>
                                    <v-list-item-title>
                                        <span class="secondary--text text-h8">
                                            {{$t('authorizationList.orderBy')}}
                                        </span>
                                    </v-list-item-title>
                                </template>

                                <v-row>
                                    <v-col>
                                        <v-select
                                            v-model="orderSelected"
                                            :items="orderByItems"
                                            :menu-props="{ maxHeight: '400' }"
                                            background-color="white"
                                            dense
                                            return-object
                                            outlined
                                            hide-details
                                            class="py-0 rounded"
                                        >
                                        </v-select>
                                    </v-col>
                                </v-row>

                            </v-list-group>
                        </v-list>
                    </v-col>
                </v-row>
            </v-container>
            <template v-slot:append>
                <v-container px-8>
                    <v-row>
                        <v-col class="d-flex justify-end">
                            <v-btn
                                text
                                depressed color="primary"
                                @click="clearAll"
                            >
                                {{ $t('authorizationList.cleanFilter') }}
                            </v-btn>
                            <v-btn
                                depressed
                                color="primary"
                                class="rounded"
                                @click="applyFilters"
                            >
                                {{ $t('authorizationList.apply') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </v-navigation-drawer>
    </div>
</template>
<script>
    import { status } from '@/config/authorization'
    import FiltersApplied from './FiltersApplied';
    import moment from 'moment';
    export default {
        name: "FiltersAppliedMobile",
        components: { FiltersApplied },
        props: {
            coverageFilters: Array,
            statusFilters: Array,
            orderByItems: Array,
            cleaningTrigger : Object
        },
        data: ()=> ({
            filtersMobile: false,
            selectedTypes: [],
            selectedStatus: [],
            stateList: [],
            showStatesFilters: false,
            orderSelected: "createdAt",
            filters: {
                coverageTypes: {},
                statuses: {},
                orderBy: "createdAt",
                sort: "DESC",
                clearAll: false,
                fromDate: '',
                toDate: '',
            },
            menu: false,
            dates: [],
            selectedDateChip: '',
            colapse: {
                coverages: false,
                states: false,
                dates: false,
                orderBy: false
            }
        }),
        methods: {
            onSelectedTypes() {
                let selectedStates;
                if(this.selectedTypes.length === 0 || this.selectedTypes.length > 1){
                    selectedStates = status.find(state => state.coverageType === 'DEFAULT')
                }
                else{
                    selectedStates = status.find(state => state.coverageType === this.selectedTypes[0].value)
                    if (!selectedStates) {
                        selectedStates = status.find(state => state.coverageType === 'DEFAULT')
                    }
                }

                this.stateList = selectedStates.states.map(state => ({...state, text: this.$t(state.text)}))
            },
            clearAll() {
                this.selectedTypes = []
                this.selectedStatus = []
                this.stateList = []
                this.dates = []
                this.showStatesFilters = false;
                this.orderSelected = ""
                this.filters.clearAll = true;
                this.filters.fromDate = ''
                this.filters.toDate = ''
                this.selectedDateChip = ''
            },
            applyFilters() {
                this.filters.coverageTypes = this.selectedTypes || {};
                this.filters.statuses = this.selectedStatus || {};
                this.filters.orderBy = this.orderSelected.value || null;
                this.filters.sort = this.orderSelected.direction || null;
                this.filters.clearAll = false;
                this.filtersMobile = false;
                this.colapseAll();
                this.$emit('applyFilters', this.filters);
            },
            removeCoverageFilter(typeApplied){
                const removeIndex = this.selectedTypes.map(type => type.value).indexOf(typeApplied.value);
                this.selectedTypes.splice(removeIndex, 1);
            },
            removeStatusFilter(statusApplied){
                const removeIndex = this.selectedStatus.map(status => status.value).indexOf(statusApplied.value);
                this.selectedStatus.splice(removeIndex, 1);
            },
            removeSelectedDates(){
                this.clearDates()
            },
            clearDates(){
                this.dates = [];
                this.filters.fromDate = '';
                this.filters.toDate = '';
                this.selectedDateChip = '';
            },
            selectedDate(date) {
                this.filters.fromDate = moment(date[0]).toISOString();
                this.filters.toDate = moment(date[1]).endOf('day').toISOString();
                this.selectedDateChip = date[0]  + " ~ " + date[1];
            },
            getFormattedPickerDates: function() {
                this.formattedDates = [];
                this.dates.forEach(date => {
                    this.formattedDates.push(moment(date).format('DD/MM/YYYY'));
                });
                return this.formattedDates.join(' ~ ');
            },
            closeFilters(){
                this.filtersMobile = false;
                this.colapseAll();
            },
            colapseAll(){
                this.colapse.coverages= false;
                this.colapse.states= false;
                this.colapse.dates= false;
                this.colapse.orderBy= false;
            }
        },
        computed : {
            filtersSelected(){
                if(typeof this.selectedTypes.length > 0 ||
                this.selectedStatus.length > 0 ||
                this.selectedDateChip !== ''){
                    return true
                }
                return false
            },
            dateRangeText: {
                get() {
                    return this.getFormattedPickerDates();
                },
                set() {
                    this.formattedDates = '';
                    this.dates = [];
                }
            }
        },
        mounted(){
          if(this.cleaningTrigger){
            this.cleaningTrigger.$on('clearAll', this.clearAll)
          }
        }
    }
</script>
<style lang="scss">
    .osg-grid{
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto;
    }
    .v-input--selection-controls:active
    .v-input--selection-controls__input:hover
    .v-input--selection-controls__ripple:before
    .v-list-item--link:before
    .v-list-item--link:active
    {
        background: none !important;
    }
</style>
